import { preloadImages, preloadFonts } from "./utils";
import LocomotiveScroll from "locomotive-scroll";

// Select essential elements
const backtopEl = document.querySelector(".backtop");
const headerEl = document.querySelector("#header");
const scrollContainer = document.querySelector("[data-scroll-container]");

// Validate essential elements
if (!scrollContainer) {
  console.error("Error: [data-scroll-container] not found!");
}
if (!backtopEl) {
  console.error("Error: .backtop element not found!");
}
if (!headerEl) {
  console.error("Error: #header element not found!");
}

let initialized = false; // Prevent double initialization

// Function to handle initialization
const initialize = () => {
  if (initialized) return; // Ensure this runs only once
  initialized = true;

  // Remove loading class from body
  document.body.classList.remove("loading");

  // Hide the loader
  const loaderEl = document.querySelector(".loader");
  if (loaderEl) {
    loaderEl.style.display = "none";
  }

  // Initialize LocomotiveScroll
  if (scrollContainer) {
    const scroll = new LocomotiveScroll({
      el: scrollContainer,
      smooth: true,
    });

    // Attach scroll to top functionality
    if (backtopEl && headerEl) {
      backtopEl.addEventListener("click", () => scroll.scrollTo(headerEl));
    }
  } else {
    console.error("LocomotiveScroll could not initialize: scroll container missing.");
  }
};

// Set a timeout for loader fallback
const timeout = setTimeout(initialize, 5000);

// Preload images and fonts
Promise.allSettled([preloadImages(".tiles__line-img"), preloadFonts("rmd7deq")])
  .then((results) => {
    const failed = results.filter((result) => result.status === "rejected");
    if (failed.length > 0) {
      console.warn("Some resources failed to load:", failed);
    }

    // Clear timeout and initialize
    clearTimeout(timeout);
    initialize();
  })
  .catch((error) => {
    console.error("Error during resource preloading:", error);
    clearTimeout(timeout);
    initialize(); // Ensure initialization even if preload fails
  });